import { createContext, useContext, useState } from "react";

const EmployeeContext = createContext();

export const useShiftEmployee = () => {
    return useContext(EmployeeContext);
};

function ShiftEmployeeContext({ children, forStructure }) {

    // Inisialisasi visibleStatus berdasarkan data dari API
    const [visibleStatus, setVisibleStatus] = useState(() => {
        const initialStatus = {};
        forStructure.length &&
            forStructure.forEach((item) => {
                initialStatus[item.jobPosition] = item.employeeAssignShift.length; // Semua jobPosition di-expand
            });
        return initialStatus;
    });

    // Fungsi untuk toggle antara expand dan collapse
    const toggleExpand = (jobPosition, totalEmployees) => {
        setVisibleStatus((prevState) => ({
            ...prevState,
            [jobPosition]: prevState[jobPosition] > 0 ? 0 : totalEmployees,
        }));
    };

    const contextVal = {
        visibleStatus,
        toggleExpand,
    };

    return (
        <EmployeeContext.Provider value={contextVal}>
            {children}
        </EmployeeContext.Provider>
    );
}

export default ShiftEmployeeContext;
