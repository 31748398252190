import React from "react";

function HoverComponent({
  shift,
  baseOnJobposition,
  handleShowAssign,
  itemEmp,
  roleUser,
  lengthAllAssignShift,
  allAssignShiftData,
}) {
  const toAssignData = {
    itemEmp,
    shift: shift?.toAssignShift,
    allAssignShiftData,
    // lengthAllAssignShift: lengthAllAssignShift,
  };

  return (
    <div
      // key={index}
      onClick={() => handleShowAssign(toAssignData, itemEmp?.uId)}
      className={`w-full h-full ${
        // shift.toAssignShift &&
        // shift.toAssignShift.length !== 0 &&
        // className={`w-full h-full ${shift.atLeastOneTrue && baseOnJobposition.includes(true) && !roleUser
        shift.atLeastOneTrue &&
          shift.assignAndConflick.length === 0 &&
          shift.hoverBaseAssignShift &&
          baseOnJobposition.length > 0 &&
          baseOnJobposition.includes(true) &&
          roleUser
          ? "group hover:bg-gray-300 cursor-pointer flex justify-center items-center duration-300 transition-all"
          : "invisible"
        }`}
    >
      {/* <h1 className="text-red-500">{`${
        shift.hoverBaseAssignShift ? "true" : "false"
      }`}</h1> */}
      <h1 className=" group-hover:block hidden duration-300 transition-all text-gray-600">
        + Assign Shift{" "}
        <span className="text-red-500">{lengthAllAssignShift}</span>
      </h1>
    </div>
  );
}


export default HoverComponent;
