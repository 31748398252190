import React from "react";

function ExpandEmployee({ isExpanded }) {
    return (
        <div className="w-[40px] h-[20px] flex justify-center items-center p-1 hover:border-[1px] rounded-md border-gray-300  cursor-pointer">
            {!isExpanded ? (
                <ChevronBottom
                    color={"black"}
                    className={"font-bold w-[20px] h-[20px]"}
                />
            ) : (
                <FiChevronRigthThin />
            )}
        </div>
    );
}

export default ExpandEmployee;

function ChevronBottom({ color, className, ...props }) {
    return (
        <svg
            className={`${className}`}
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                d="M6 9L12 15L18 9"
                stroke={color}
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
}

function FiChevronRigthThin() {
    return (
        <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M9 18L15 12L9 6"
                stroke="#201140"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
}
