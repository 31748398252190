import React from "react";
import PropTypes from "prop-types";
import { useFormikContext } from "formik";
import {
  InputText,
  InputSelect,
  CountrySelect,
  UploadImg1,
} from "@bluesilodev/timhutcomponents";
import CustomDropdown from "components/TestInputSelectClickable";
import { useGetSettingByDefaultQuery } from "services/employeeAPI";

function RegisterCompany({ countries }) {
  const { handleBlur, values, handleChange, touched, errors, setFieldValue } =
    useFormikContext();

  const changeFile = (e) => {
    const file = e.target.files[0];

    if (file) {
      setFieldValue("companyImage", file);
    }
  };

  const { data: dataSetting, isPending: loadingUpdateSetting } =
    useGetSettingByDefaultQuery({
      onSuccess: () => {},
      onError: () => {},
    });

  return (
    <div className="flex flex-col gap-3 w-full font-latoRegular">
      <div className="my-5">
        <h1 className="font-bold text-2xl font-latoBold">Company Details</h1>
        <p className="font-semibold font-latoBold">
          Fill Detail Your Company details
        </p>
      </div>

      <div className="flex flex-col gap-3 w-full">
        <InputText
          id="companyName"
          title={"Company Name"}
          label={null}
          required={true}
          value={values.companyName}
          onChange={handleChange}
          onBlur={handleBlur}
          error={
            <>
              {errors.companyName && touched.companyName && (
                <div className="text-red-500">{errors.companyName}</div>
              )}
            </>
          }
        />
        <InputText
          id="uenNumber"
          title={"UEN Number"}
          label={null}
          required={true}
          value={values.uenNumber}
          onChange={handleChange}
          onBlur={handleBlur}
          error={
            <>
              {errors.uenNumber && touched.uenNumber && (
                <div className="text-red-500">{errors.uenNumber}</div>
              )}
            </>
          }
        />
        <InputSelect
          title={"Employee Head Count"}
          name="employeeHeadCount"
          options={[
            ...(dataSetting?.data?.data?.employeeHeadCount?.map((val) => ({
              label: val,
              value: val,
            })) || []),

            // { label: "0 - 10", value: "0 - 10" },
            // { label: "11 - 50", value: "11 - 50" },
            // { label: "51 - 100", value: "51 - 100" },
            // { label: "> 100", value: "> 100" },
          ]}
          value={values.employeeHeadCount}
          onChange={handleChange}
          onBlur={handleBlur}
          required={true}
          error={
            <>
              {errors.employeeHeadCount && touched.employeeHeadCount && (
                <div className="text-red-500">{errors.employeeHeadCount}</div>
              )}
            </>
          }
        />
        {/* <TestInputSelectClickable
          title={"Employee Head Count"}
          name="employeeHeadCount"
          options={[
            ...(dataSetting?.data?.data?.employeeHeadCount?.map((val) => ({
              label: val,
              value: val,
            })) || []),

            // { label: "0 - 10", value: "0 - 10" },
            // { label: "11 - 50", value: "11 - 50" },
            // { label: "51 - 100", value: "51 - 100" },
            // { label: "> 100", value: "> 100" },
          ]}
          value={values.employeeHeadCount}
          onChange={handleChange}
          onBlur={handleBlur}
          required={true}
          error={
            <>
              {errors.employeeHeadCount && touched.employeeHeadCount && (
                <div className="text-red-500">{errors.employeeHeadCount}</div>
              )}
            </>
          }
        /> */}

        <InputSelect
          title={"Which Industry best represents your company?"}
          id="industryField"
          required={true}
          options={[
            ...(dataSetting?.data?.data?.companyIndustry?.map((val) => ({
              label: val,
              value: val,
            })) || []),

            // { label: "Technology", value: "Technology" },
            // { label: "Finance", value: "Finance" },
            // { label: "Healthcare", value: "Healthcare" },
            // { label: "Retail", value: "Retail" },
            // { label: "Manufacturing", value: "Manufacturing" },
            // { label: "Education", value: "Education" },
            // { label: "Government", value: "Government" },
            // { label: "Hospitality", value: "Hospitality" },
            // { label: "Energy", value: "Energy" },
            // { label: "Transportation", value: "Transportation" },
            // { label: "Others", value: "Others" },
          ]}
          value={values.industryField}
          onChange={handleChange}
          onBlur={handleBlur}
          error={
            <>
              {errors.industryField && touched.industryField && (
                <div className="text-red-500">{errors.industryField}</div>
              )}
            </>
          }
        />
        <div className="flex w-full items-center gap-2">
          <CountrySelect
            isTitle={false}
            classname={""}
            onChange={(value) =>
              setFieldValue("companyCountryPhoneNumber", value.code)
            }
            value={`${values.companyCountryPhoneNumber}`}
            error={
              <div>
                {errors.companyCountryPhoneNumber &&
                  touched.companyCountryPhoneNumber &&
                  errors.companyCountryPhoneNumber}
              </div>
            }
          />
          <InputText
            id="companyPhoneNumber"
            min={0}
            type={"number"}
            title={"Phone Number"}
            label={null}
            value={values.companyPhoneNumber}
            onChange={handleChange}
            onBlur={handleBlur}
            required={true}
            error={
              <>
                {errors.companyPhoneNumber && touched.companyPhoneNumber && (
                  <div className="text-red-500">
                    {errors.companyPhoneNumber}
                  </div>
                )}
              </>
            }
          />
        </div>
        <InputSelect
          id="country"
          title={"Country"}
          options={countries}
          value={values.country}
          onChange={handleChange}
          onBlur={handleBlur}
          required={true}
          error={
            <>
              {errors.country && touched.country && (
                <div className="text-red-500">{errors.country}</div>
              )}
            </>
          }
        />
        <InputText
          id="address"
          title={"Address"}
          label={null}
          required={true}
          value={values.address}
          onChange={handleChange}
          onBlur={handleBlur}
          error={
            <>
              {errors.address && touched.address && (
                <div className="text-red-500">{errors.address}</div>
              )}
            </>
          }
        />
        <InputText
          id="postalCode"
          min={0}
          type={"number"}
          label={null}
          title={"Postal Code"}
          required={true}
          value={values.postalCode}
          onChange={handleChange}
          onBlur={handleBlur}
          error={
            <>
              {errors.postalCode && touched.postalCode && (
                <div className="text-red-500">{errors.postalCode}</div>
              )}
            </>
          }
        />
        <UploadImg1
          onChange={changeFile}
          onBlur={handleBlur}
          required={true}
          id="companyImage"
          title={"Company Logo"}
          accept="image/jpeg, image/jpg, image/png"
          textSizeLimit={
            "For Best Resolution is 1366 x 1366px, Max upload image size is 2MB, Supported files: .jpg, .png, .jpeg "
          }
          message={
            <>
              {errors.companyImage && touched.companyImage ? (
                <div className="text-red-500">{errors.companyImage}</div>
              ) : (
                <h1>{values.companyImage.name}</h1>
              )}
            </>
          }
        />
      </div>
    </div>
  );
}

RegisterCompany.propTypes = {
  countries: PropTypes.array,
};

RegisterCompany.defaultProps = {
  countries: [],
};

export default RegisterCompany;
