import React, { useState } from "react";
import { useFormikContext } from "formik";
import {
  InputText,
  InputPassword,
  CountrySelect,
  UploadImg1,
} from "@bluesilodev/timhutcomponents";

function RegisterUser() {
  const { handleBlur, values, handleChange, touched, errors, setFieldValue } =
    useFormikContext();
  const [preview, setPreview] = useState(null);

  const changeFile = (e) => {
    const file = e.currentTarget.files[0];

    if (file) {
      setFieldValue("photo", file);
      setPreview(URL.createObjectURL(file));
    } else {
      setPreview(null)
    }
  };

  return (
    <div className="flex flex-col gap-3 w-full font-latoRegular">
      <div className="my-5">
        <h1 className="font-bold text-2xl font-latoBold">Register Your Account!</h1>
        <h1 className="font-semibold font-latoBold">Fill Detail Your Account</h1>
      </div>

      <InputText
        id="firstName"
        title={"First Name"}
        value={values.firstName}
        onChange={handleChange}
        required={true}
        label={null}
        onBlur={handleBlur}
        error={
          <>
            {errors.firstName && touched.firstName && (
              <h1 className="text-red-500">{errors.firstName}</h1>
            )}
          </>
        }
      />

      <InputText
        id="lastName"
        title={"Last Name"}
        onChange={handleChange}
        value={values.lastName}
        required={true}
        label={null}
        onBlur={handleBlur}
        error={
          <>
            {errors.lastName && touched.lastName && (
              <h1 className="text-red-500">{errors.lastName}</h1>
            )}
          </>
        }
      />

      <InputText
        id="userName"
        title={"Username"}
        onChange={handleChange}
        value={values.userName}
        required={true}
        label={null}
        onBlur={handleBlur}
        error={
          <>
            {errors.userName && touched.userName && (
              <h1 className="text-red-500">{errors.userName}</h1>
            )}
          </>
        }
      />

      <InputText
        id="email"
        title={"Email Address"}
        onChange={handleChange}
        value={values.email}
        type={"email"}
        required={true}
        label={null}
        error={
          <>
            {errors.email && touched.email && (
              <h1 className="text-red-500">{errors.email}</h1>
            )}
          </>
        }
      />

      <InputPassword
        id="password"
        title={"Password"}
        onChange={handleChange}
        value={values.password}
        label={null}
        required={true}
        onBlur={handleBlur}
        error={
          <>
            {errors.password && touched.password && (
              <h1 className="text-red-500">{errors.password}</h1>
            )}
          </>
        }
      />

      <InputPassword
        id="confirmPassword"
        title={"Confirm Password"}
        onChange={handleChange}
        value={values.confirmPassword}
        onBlur={handleBlur}
        label={null}
        required={true}
        error={
          <>
            {errors.confirmPassword && touched.confirmPassword && (
              <h1 className="text-red-500">{errors.confirmPassword}</h1>
            )}
          </>
        }
      />

      <div className="flex w-full h-full gap-3  justify-between">
        <CountrySelect
          isTitle={false}
          classname={""}
          onChange={(value) => setFieldValue("countryPhoneNumber", value.code)}
          value={`${values.countryPhoneNumber}`}
          error={
            <div>
              {errors.countryPhoneNumber &&
                touched.countryPhoneNumber &&
                errors.countryPhoneNumber}
            </div>
          }
        />
        <InputText
          id="phoneNumber"
          min={0}
          onChange={handleChange}
          value={values.phoneNumber}
          required={true}
          label={null}
          type={"number"}
          title={"Phone Number"}
          onBlur={handleBlur}
          error={
            <>
              {errors.phoneNumber && touched.phoneNumber && (
                <h1 className="text-red-500">{errors.phoneNumber}</h1>
              )}
            </>
          }
        />
      </div>
      <UploadImg1
        onChange={changeFile}
        textSizeLimit={
          "For Best Resolution is 1366 x 1366px, Max upload image size is 2MB, Supported files: .jpg, .png "
        }
        title={"Profile Picture"}
        onBlur={handleBlur}
        id="photo"
        accept="image/jpeg, image/jpg, image/png"
        message={
          <>
            {errors.photo && touched.photo ? (
              <h1 className="text-red-500">{errors.photo}</h1>
            ) : (
              <h1>{values.photo.name}</h1>
            )}
          </>
        }
      />
       {preview && (
        <div className="mt-4">
          <h2 className="font-semibold text-[16px]">Preview</h2>
          <img
            src={preview}
            alt="Preview"
            className="w-[200px] h-[200px] object-cover border border-gray-300 rounded-md"
          />
        </div>
      )}
    </div>
  );
}

export default RegisterUser;
