import React from "react";
import { ConflictIcon, ReplacementSvg } from "../Icon";

function ConflickComponent({
  data,
  handleConflickAssign,
  assignShift,
  handleEditAssignShift,
  allShift,
  employeeData,
  selectView,
}) {
  const combineShiftAndFilterData = {
    allShift: allShift,
    assignShift: assignShift,
    employee: employeeData,
  };

  // console.log("assignSHIFT: ", assignShift);

  return (
    <div className="mt-4 text-[14px] ">
      {data.map((item, index) => {
        const shiftDetails = item?.shiftDetails;
        const employee = item?.employeeData;

        return (
          <div key={index} className="relative cursor-pointer mt-2 mb-2">
            <div
              style={{
                backgroundColor: item?.shiftDetails?.color,
              }}
              onClick={() =>
                handleEditAssignShift(combineShiftAndFilterData, item.uId)
              }
              className={`flex flex-col justify-center items-center h-[56px] w-full cursor-pointer rounded-md ${!item?.isActive && "opacity-60"
                }`}
            >
              {selectView === "Employee View" && (
                <>
                  <h1>{shiftDetails?.shiftName} </h1>
                </>
              )}

              {selectView === "Job Position View" && (
                <>
                  {" "}
                  <h1>{employee?.firstName} </h1>{" "}
                </>
              )}
              <p>
                {item?.shiftDetails?.startTime} - {item?.shiftDetails?.endTime}
              </p>
            </div>

            {item.isReplacement ? (
              <div
                className="absolute top-[-5px] right-[-5px]"
                onClick={() => handleConflickAssign(assignShift)}
              >
                <ReplacementSvg />
              </div>
            ) : (
              <div
                className="absolute top-[-5px] right-[-5px]"
                onClick={() => handleConflickAssign(assignShift)}
              >
                <ConflictIcon />
              </div>
            )}
          </div>
        );
      })}
    </div>
  );
}

export default ConflickComponent;
