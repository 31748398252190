import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Navigate, useLocation } from "react-router-dom";

import AdminDashboardPage from "pages/admin/dashboard";
import EmployeeDashboardPage from "pages/user/dashboard";

import { useGetAppQuery } from "services/commonAPI";
import { useGetLeaveQuery } from "services/leaveAPI";
import { useGetEventQuery, useGetBirthdayQuery } from "services/employeeAPI";
import EventCalendarIcon from "assets/eventcalendar.svg";

const allowedPaths = ["/resetPassword", "/confirmEmail", "/forgotPassword"];

const DashboardPage = ({ children }) => {
  const location = useLocation();
  // Get currentUser from the persisted state
  const { currentUser: stateCurrentUser, currentRole: stateCurrentRole } =
    useSelector((state) => state.userData);

  // Check if the current path is allowed
  const isAllowedPath = allowedPaths.some((path) =>
    location.pathname.startsWith(path)
  );

  // Fallback to localStorage if stateCurrentUser is null
  const storedUser = stateCurrentUser
    ? null
    : localStorage.getItem("currentUser");
  const currentUser =
    stateCurrentUser || (storedUser ? JSON.parse(storedUser) : null);
  const currentRole =
    stateCurrentRole || (currentUser?.role ? currentUser.role[0] : "");

  // calendar fetching logic and API
  const [currentMonth, setCurrentMonth] = useState(() => {
    const now = new Date();
    return `${now.getFullYear()}-${String(now.getMonth() + 1).padStart(
      2,
      "0"
    )}`;
  });
  const [dateData, setDateData] = useState(0);
  const [apps, setApps] = useState([]);

  const { data: resApp, isSuccess: isSuccessApp } = useGetAppQuery();

  useEffect(() => {
    if (isSuccessApp && resApp?.data) {
      setApps(resApp?.data?.data);
    }
  }, [isSuccessApp, resApp]);

  const [dashboardData, setDashboardData] = useState({
    eventData: [],
    birthdayData: [],
    leaveData: [],
  });

  const { data: eventRespData, refetch: refetchEvent } = useGetEventQuery();
  const { data: birthdayRespData, refetch: refetchBirthday } =
    useGetBirthdayQuery();
  const { data: leaveRespData, refetch: refetchLeave } = useGetLeaveQuery();

  console.log("state current user", stateCurrentUser);

  useEffect(() => {
    if (eventRespData && eventRespData.success) {
      console.log("event data", eventRespData);
      setDashboardData((prevData) => ({
        ...prevData,
        eventData: eventRespData?.data?.data,
      }));
    }
    if (birthdayRespData && birthdayRespData.success) {
      console.log("birthday data", birthdayRespData);
      setDashboardData((prevData) => ({
        ...prevData,
        birthdayData: birthdayRespData?.data?.data,
      }));
    }
    if (leaveRespData && leaveRespData.success) {
      console.log("leave data", leaveRespData);
      setDashboardData((prevData) => ({
        ...prevData,
        leaveData: leaveRespData?.data?.data,
      }));
    }
  }, [eventRespData, birthdayRespData, leaveRespData]);

  useEffect(() => {
    const getTimeUntilNextMonth = () => {
      const now = new Date();
      const nextMonth = new Date(now.getFullYear(), now.getMonth() + 1, 1);
      return nextMonth - now;
    };

    // Set timeout to update the month at the beginning of the next month
    const timeoutId = setTimeout(() => {
      const now = new Date();
      const newMonth = `${now.getFullYear()}-${String(
        now.getMonth() + 1
      ).padStart(2, "0")}`;
      setCurrentMonth(newMonth);
    }, getTimeUntilNextMonth());

    // Cleanup timeout on component unmount
    return () => clearTimeout(timeoutId);
  }, [currentMonth]);

  useEffect(() => {
    // Refetch calendar data when the current month changes
    refetchEvent();
    refetchBirthday();
    refetchLeave();
  }, [currentMonth, refetchEvent, refetchBirthday, refetchLeave]);

  const transformedEventData =
    dashboardData?.eventData?.length > 0
      ? dashboardData?.eventData?.map((event) => ({
          imgSrc: EventCalendarIcon,
          name: event.nameEvent,
          date: event.startDate,
        }))
      : [];

  const transformedBirthdayData =
    dashboardData?.birthdayData?.length > 0
      ? dashboardData?.birthdayData?.map((birthday) => ({
          imgSrc: EventCalendarIcon,
          name: birthday.name,
          date: birthday.dateOfBirth,
        }))
      : [];

  const transformedLeaveData =
    dashboardData?.leaveData?.length > 0
      ? dashboardData?.leaveData?.map((leave) => {
          // Get the newest date from leaveTimes
          const latestLeaveTime = leave.leaveTimes.reduce((latest, current) => {
            return new Date(current.date) > new Date(latest.date)
              ? current
              : latest;
          }, leave.leaveTimes[0]);

          return {
            imgSrc: EventCalendarIcon,
            name: leave.leaveType.name,
            date: latestLeaveTime.date,
            employeeName: leave.employeeInformation.firstName,
          };
        })
      : [];

  const exampleOptions = [
    {
      id: "1",
      value: "Events",
      dataList: transformedEventData,
    },
    {
      id: "2",
      value: "On Leave",
      dataList: transformedLeaveData,
    },
    {
      id: "3",
      value: "Birthday",
      dataList: transformedBirthdayData,
    },
  ];

  if (!currentUser && !isAllowedPath) {
    // Redirect to login if no current user
    console.log("No current user, redirecting to login");
    return <Navigate to="/login" />;
  }

  if (currentRole?.includes("Admin")) {
    return (
      <AdminDashboardPage
        currentUserName={currentUser.userName}
        setDateData={setDateData}
        exampleOptions={exampleOptions}
        day={dateData}
        currentMonth={currentMonth}
        linkApps={apps}
      />
    );
  } else {
    return (
      <EmployeeDashboardPage
        currentUserName={currentUser?.userName}
        setDateData={setDateData}
        exampleOptions={exampleOptions}
        day={dateData}
        currentMonth={currentMonth}
      />
    );
  }
};

export default DashboardPage;
