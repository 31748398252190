import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { startOfMonth, setDate } from "date-fns";
import {
  WidgetCard,
  Button,
  InputDateWeekPicker,
  ShiftListBox,
} from "@bluesilodev/timhutcomponents";
import TestShiftListBox from "components/ShiftListBox/ShiftListBox";
import TestingShiftListBox from "components/TestingShiftListBox/ShiftListBox/ShiftListBox";
import { useFormik } from "formik";
import {
  useGetEmployeeShiftQuery,
  useGetDashboardQuery,
  useGetShiftQuery,
  useGetLocationQuery,
} from "services/schedulingAPI";
import { useGetAllEmployeesQuery } from "services/employeeAPI";
import { getAllLeaveByDate } from "services/leaveAPI";
import { getAllAttendanceByDate } from "services/attendanceAPI";
import {
  generateDateRange,
  formatMonthYear,
  handleChangeApp,
} from "utils/utils";
import { useDivRef } from "utils/context";

import TestModalDialog from "components/testModalDialog";
import EventForm from "components/modal/EventModal";
import CustomCalendar from "components/calendar";
import Tabs from "components/tabs";
import ListInputSelect from "components/listInputSelect";
import {
  TestInputDateRange,
  TestInputWeekPicker,
  TestInputWeekPickerRange,
} from "components/testInputDateRange";
import { ProgressBar, ColorLabel } from "utils/charts/chart";
import {
  TestDonutChart,
  TestDonutChart2,
  TestDonutChart3,
} from "utils/charts/testChart";

import CalendarIcon from "assets/Calendar.svg";

const AdminDashboardPage = ({
  currentUserName,
  setDateData,
  exampleOptions,
  day,
  currentMonth,
  linkApps,
}) => {
  const { currentRole } = useSelector((state) => state.userData);
  const [keywordName, setKeywordName] = useState("");
  const [showTabsForm, setShowTabsForm] = useState(false);
  const [allEmployeesData, setAllEmployeesData] = useState([]);
  const [employeeViewInput, setEmployeeViewInput] = useState("Employee View");
  const [dataEmployeesShift, setDataEmployeesShift] = useState([]);
  const [employeeCount, setEmployeeCount] = useState({});
  const [dataShift, setDataShift] = useState([]);
  const [dataDashboard, setDataDashboard] = useState([]);
  const [dataLocation, setDataLocation] = useState([]);
  const [dataSelectLocation, setDataSelectLocation] = useState("");
  const [dateValue, setDateValue] = useState(
    generateDateRange(startOfMonth(new Date()), setDate(new Date(), 7))
  );

  const [dateRange, setDateRange] = useState({});
  const { values, setFieldValue } = useFormik({
    initialValues: {
      date: dateValue, // Set initial date value
    },
  });
  const [fetchDataDateRange, setFetchDataDateRange] = useState({
    startDate: "",
    endDate: "",
  });
  const [attendanceDataByWeek, setAttendanceDataByWeek] = useState([]);
  const [leaveDataByWeek, setLeaveDataByWeek] = useState([]);
  const [attendanceSummary, setAttendanceSummary] = useState({});

  // link to shift scheduling app module
  const linkShowAppShift = linkApps?.find(
    (link) => link.name === "Scheduling"
  )?.link;

  const handleClickSeeMore = () => {
    handleChangeApp(linkShowAppShift);
  };

  // get div context for window height
  const { remainingHeight } = useDivRef();

  // for setting date value for date picker or range component
  useEffect(() => {
    setFieldValue("date", dateValue);
    console.log("date value", dateValue);
  }, [dateValue, setFieldValue]); // Only depend on dateValue

  useEffect(() => {
    setDateValue(values.date);
    console.log("values date", values.date);
  }, [values.date]);

  useEffect(() => {
    if (Array.isArray(dateValue) && dateValue.length > 0) {
      setDateRange({
        fromDate: dateValue[0], // First date in the range
        toDate: dateValue[dateValue.length - 1], // Last date in the range
      });
    }
  }, [dateValue]);

  const { data: allEmployeesRespData, isLoading: loadingAllEmployeesData } =
    useGetAllEmployeesQuery();

  // fetching all employees data
  useEffect(() => {
    if (allEmployeesRespData && allEmployeesRespData?.data?.data?.length > 0) {
      // console.log('all employee', allEmployeesRespData)
      // setDataEmployeesShift(allEmployeesRespData.data.data);
      setAllEmployeesData(allEmployeesRespData.data.data);

      const employeeTypeCounts = allEmployeesRespData?.data?.data.reduce(
        (acc, employee) => {
          const type = employee?.employeeStatus?.employeeType || "";

          // Increment or initialize count for the employeeType
          acc[type] = acc[type] ? acc[type] + 1 : 1;

          return acc;
        },
        {}
      );

      //   // Step 2: Convert the result into an array of objects
      //   const result = Object.keys(employeeTypeCounts)?.map(key => ({
      //     [key]: employeeTypeCounts[key],
      //   }));

      const totalCount = Object.values(employeeTypeCounts)?.reduce(
        (acc, count) => acc + count,
        0
      );

      // Step 3: Convert the result into an array of objects and calculate percentages
      const result = Object.keys(employeeTypeCounts)?.map((key) => {
        const count = employeeTypeCounts[key];
        const percentage = ((count / totalCount) * 100).toFixed(2); // Calculate percentage

        return {
          type: key,
          percentage: parseFloat(percentage), // Convert to float
          value: count,
        };
      });

      console.log("result", result);

      // Step 4: Add total percentage and count to the result
      const totalPercentage = 100; // This is always 100% for the total
      const totalResult = {
        total: {
          percentage: totalPercentage,
          value: totalCount,
        },
        employeeTypes: result,
      };
      console.log("total result", totalResult);

      setEmployeeCount(totalResult);
    } else {
      // setDataEmployeesShift([]);
      setAllEmployeesData([]);
      setEmployeeCount([]); // Set to empty if API response is empty or unsuccessful
    }
  }, [allEmployeesRespData]);

  const { data: employeeShiftRespData, isLoading: loadingEmployeeShift } =
    useGetEmployeeShiftQuery({
      firstName: keywordName,
      startDate: dateRange?.fromDate,
      endDate: dateRange?.toDate,
    });

  useEffect(() => {
    if (
      employeeShiftRespData &&
      employeeShiftRespData?.data?.data?.length > 0
    ) {
      // Directly set employee data without filtering
      const allEmployeesShift = employeeShiftRespData.data.data.map((item) => {
        return { ...item, employee: item.employee || [] }; // Ensure employee is an array
      });

      console.log("all employees data", allEmployeesShift);

      setDataEmployeesShift(allEmployeesShift); // Set all employees directly
      // setTimeout(() => console.log('Updated employee shift data in useEffect', allEmployeesShift), 0);
    } else {
      setDataEmployeesShift([]); // Set to empty if API response is empty or unsuccessful
    }
  }, [employeeShiftRespData]);

  console.log("employee shift data", dataEmployeesShift);
  useEffect(() => {
    console.log("Updated employee shift data", dataEmployeesShift);
  }, [dataEmployeesShift]);

  const { data: shiftRespData, isLoading: loadingGetShift } =
    useGetShiftQuery();

  const { data: locationRespData, isLoading: loadingGetLocation } =
    useGetLocationQuery();

  const { data: dashboardRespData, isLoading: loadingGetDashboard } =
    useGetDashboardQuery({
      fromDate: dateRange.startDate?.toISOString().slice(0, 10),
      toDate: dateRange.endDate?.toISOString().slice(0, 10),
    });

  useEffect(() => {
    if (shiftRespData && shiftRespData?.success) {
      setDataShift(shiftRespData.data.data);
    }
    if (locationRespData && locationRespData?.success) {
      console.log("location resp data", locationRespData);
      const locationDataMap =
        locationRespData.data.data.length > 0 &&
        locationRespData.data.data.map((item) => {
          return {
            label: item.locationName,
            value: item.uId,
          };
        });

      setDataLocation(locationDataMap || []);
    }
    if (dashboardRespData && dashboardRespData?.success) {
      setDataDashboard(dashboardRespData.data.data);
    }
  }, [shiftRespData, locationRespData, dashboardRespData]);

  // console.log('shift data', dataShift);
  // console.log('count data', employeeCount);
  console.log("location data", dataLocation);

  const HandleToggleTabsForm = () => {
    setShowTabsForm(!showTabsForm);
  };

  // const stages = [
  //     { progress: 50, color: '#4BD394' },
  //     { progress: 12, color: '#EFCA4F' },
  //     { progress: 12, color: '#B83C3C' },
  //     { progress: 25, color: '#A3A3AB' },
  // ];

  const stages = [
    {
      progress: attendanceSummary?.percentages?.onTimePercentage,
      color: "#4BD394",
    }, // Green
    {
      progress: attendanceSummary?.percentages?.latePercentage,
      color: "#B83C3C",
    }, // Red
    {
      progress: attendanceSummary?.percentages?.noPunchInOutPercentage,
      color: "#EFCA4F",
    }, // Yellow
    {
      progress: attendanceSummary?.percentages?.leavePercentage,
      color: "#A3A3AB",
    }, // Gray
  ];

  const mockTotalResult = {
    total: {
      percentage: 100,
      value: 100,
    },
    employeeTypes: [
      {
        type: "Male",
        percentage: 50,
        value: 50,
      },
      {
        type: "Female",
        percentage: 50,
        value: 50,
      },
    ],
  };

  const month = formatMonthYear(currentMonth) || "this month";

  useEffect(() => {
    const fetchData = async () => {
      if (
        fetchDataDateRange &&
        fetchDataDateRange.startDate !== "" &&
        fetchDataDateRange.endDate !== ""
      ) {
        console.log("fetch data", fetchDataDateRange);

        try {
          // Fetch attendance data by date range
          const attendanceDataByWeek = await getAllAttendanceByDate(
            fetchDataDateRange
          );
          let fetchedAttendanceData = [];
          if (
            attendanceDataByWeek &&
            attendanceDataByWeek?.data?.data?.length > 0
          ) {
            fetchedAttendanceData = attendanceDataByWeek?.data?.data;
            console.log("attendance data by week", fetchedAttendanceData);
            setAttendanceDataByWeek(fetchedAttendanceData);
          } else {
            console.log("No attendance data for the selected week");
          }

          // Fetch leave data by date range
          const leaveDataByWeek = await getAllLeaveByDate(fetchDataDateRange);
          let fetchedLeaveData = [];
          if (
            leaveDataByWeek &&
            leaveDataByWeek?.data?.data?.mainData?.length > 0
          ) {
            fetchedLeaveData = leaveDataByWeek.data?.data?.mainData;
            console.log("leave data by week", fetchedLeaveData);
            setLeaveDataByWeek(fetchedLeaveData);
          } else {
            console.log("No leave data for the selected week");
          }

          // Calculate summary data
          let onTimeCount = 0;
          let lateCount = 0;
          let noPunchInOutCount = 0;
          let leaveCount = fetchedLeaveData?.length || 0;

          fetchedAttendanceData?.forEach((attendance) => {
            if (attendance.status === "OnTime") {
              onTimeCount++;
            } else if (attendance.status === "Late") {
              lateCount++;
            } else if (
              attendance.status === "NoPunchInOut" ||
              attendance.status === ""
            ) {
              noPunchInOutCount++;
            }
          });

          const totalData =
            onTimeCount + lateCount + noPunchInOutCount + leaveCount;

          const resultData = {
            onTimeCount,
            lateCount,
            noPunchInOutCount,
            leaveCount,
            totalData,
            percentages: {
              onTimePercentage: (onTimeCount / totalData) * 100 || 0,
              latePercentage: (lateCount / totalData) * 100 || 0,
              noPunchInOutPercentage:
                (noPunchInOutCount / totalData) * 100 || 0,
              leavePercentage: (leaveCount / totalData) * 100 || 0,
            },
          };

          console.log(resultData);
          setAttendanceSummary(resultData);
        } catch (error) {
          console.error("Error fetching data:", error);
        }
      }
    };

    fetchData();
  }, [fetchDataDateRange]);

  return (
    <>
      <div className="mt-4">
        <p className="text-lg font-bold">Welcome Back, {currentUserName} </p>
        <p className="text-sm font-semibold">
          Here's what happening today. see the status at once
        </p>
      </div>
      <div className="flex w-full gap-4 mt-4">
        <div className="w-full grid sm:grid-cols-5 gap-2">
          <WidgetCard radius={"lg"}>
            <div className="grid grid-cols-1 items-center sm:grid-cols-2 p-1 gap-2 overflow-hidden">
              <TestDonutChart3
                xc={45}
                yc={45}
                r={35}
                totalResult={employeeCount}
                colorArray={[["#1A1A2E"], ["#DD7224"]]}
              />
              <div className="flex flex-col gap-1 mt-3">
                {employeeCount?.employeeTypes?.map((employeeType, index) => (
                  <div
                    className="gender-label"
                    style={{ position: "relative" }}
                    key={index}
                  >
                    <p className="text-xs font-semibold">
                      {Math.round(employeeType.percentage)}% (
                      {employeeType.value} employee
                      {employeeType.value !== 1 ? "s" : ""})
                    </p>
                    <p className="text-xs">
                      {employeeType.type.charAt(0).toUpperCase() +
                        employeeType.type.slice(1)}
                    </p>
                    <div
                      style={{
                        width: "8px",
                        height: "8px",
                        borderRadius: "50%",
                        backgroundColor: index === 0 ? "#1A1A2E" : "#DD7224", // Change colors as needed
                        position: "absolute",
                        top: 5,
                        left: -10,
                      }}
                    ></div>
                  </div>
                ))}
              </div>
            </div>
          </WidgetCard>
          <WidgetCard radius={"lg"}>
            <div className="grid grid-cols-1 items-center sm:grid-cols-2 p-1 gap-2 overflow-hidden">
              <TestDonutChart3
                xc={45}
                yc={45}
                r={35}
                totalResult={mockTotalResult}
                colorArray={[["#009CF3"], ["#FF52D9"]]}
              />
              {/* <div className="flex flex-col gap-1 mt-3">
                                <div className="gender-label relative">
                                    <p className="text-xs font-semibold">70% (37 employee)</p>
                                    <p className="text-xs">Male</p>
                                    <div style={{ width: '8px', height: '8px', borderRadius: '50%', backgroundColor: '#009CF3', position: "absolute", top: 5, left: -10 }}></div>
                                </div>
                                <div className="gender-label relative">
                                    <p className="text-xs font-semibold">30% (37 employee)</p>
                                    <p className="text-xs">Female</p>
                                    <div style={{ width: '8px', height: '8px', borderRadius: '50%', backgroundColor: '#FF52D9', position: "absolute", top: 5, left: -10 }}></div>
                                </div>
                            </div> */}
              <div className="flex flex-col gap-1 mt-3">
                {mockTotalResult?.employeeTypes?.map((employeeType, index) => (
                  <div className="gender-label relative" key={index}>
                    <p className="text-xs font-semibold">
                      {Math.round(employeeType.percentage)}% (
                      {employeeType.value} employee
                      {employeeType.value !== 1 ? "s" : ""})
                    </p>
                    <p className="text-xs">
                      {employeeType.type.charAt(0).toUpperCase() +
                        employeeType.type.slice(1)}
                    </p>
                    <div
                      style={{
                        width: "8px",
                        height: "8px",
                        borderRadius: "50%",
                        backgroundColor: index === 0 ? "#009CF3" : "#FF52D9", // Adjust colors as needed
                        position: "absolute",
                        top: 5,
                        left: -10,
                      }}
                    ></div>
                  </div>
                ))}
              </div>
            </div>
          </WidgetCard>
          <WidgetCard radius={"lg"}>
            <div className="flex flex-col items-center justify-center p-4">
              <div className="border border-2 border-gray-300 rounded-full p-2">
                <img src={CalendarIcon} alt="Calendar Icon" />
              </div>
              <div className="flex flex-col items-center">
                <p className="input-label mt-2">Employee On Leave</p>
                <p className="text-md font-semibold">2 Employees</p>
              </div>
            </div>
          </WidgetCard>
          <WidgetCard radius={"lg"}>
            <div className="flex flex-col items-center justify-center p-2 sm:p-4">
              <div className="border border-2 border-gray-300 rounded-full p-2">
                <img src={CalendarIcon} alt="Calendar Icon" />
              </div>
              <div className="flex flex-col items-center">
                <p className="input-label mt-2">Upcoming Payroll Schedule</p>
                <p className="text-md font-semibold">1 September 2023</p>
              </div>
            </div>
          </WidgetCard>
          <WidgetCard className="rounded-lg shadow-xl border-gray-300 border-2 grid col-span-2 sm:col-span-1 overflow-hidden ">
            <div className="flex flex-col items-center justify-center gap-3 p-1">
              <p className="attendance-label text-sm font-semibold text-center mt-2">
                Weekly Attendance Overview
              </p>
              <div className="flex justify-center items-center">
                {/* <TestInputWeekPicker setFetchDataDateRange={setFetchDataDateRange} /> */}
                <InputDateWeekPicker
                  setFetchDataDateRange={setFetchDataDateRange}
                />
              </div>
              <ProgressBar stages={stages} />
              <ColorLabel attendanceSummary={attendanceSummary} />
            </div>
          </WidgetCard>
        </div>
      </div>

      <div className="flex flex-col-reverse sm:flex-row w-full mt-4 gap-4">
        <div className="w-full p-4 sm:w-3/4 overflow-hidden border border-gray-300 shadow-xl rounded-lg">
          {/* <!-- Your content here --> */}
          <div className="flex justify-between mb-4">
            <p className="font-bold text-lg">Shift Scheduling</p>
            <button
              type="button"
              className="w-[70px] h-[30px] px-1 font-bold text-[12px] rounded-[12px] border-2 border-black hover:bg-gray-400"
              onClick={handleClickSeeMore}
            >
              See More
            </button>
          </div>
          <ListInputSelect
            employeeViewInput={employeeViewInput}
            setEmployeeViewInput={setEmployeeViewInput}
            dateValue={dateValue}
            setDateValue={setDateValue}
            setFieldValue={setFieldValue}
            setDateRange={setDateRange}
            dataEmployees={dataEmployeesShift}
            keywordValue={keywordName}
            setKeywordValue={setKeywordName}
          />
          {/* <ShiftListBox
                        selectView = {employeeViewInput}
                        dataDate={dateValue}
                        dataEmployees={dataEmployeesShift}
                        shiftDataTemplate={dataShift}
                        dataAssignShift={dataDashboard}
                        locationData={dataLocation}
                        locationState={dataSelectLocation}
                        onChangeLocation={(e) => {
                            setDataSelectLocation(e.target.value);
                        }}
                        // roleUser={currentRole === "Admin"}
                        divHeightEmployee={remainingHeight}
                    /> */}
          <TestingShiftListBox
            selectView={employeeViewInput}
            dataDate={dateValue}
            dataEmployees={dataEmployeesShift}
            shiftDataTemplate={dataShift}
            dataAssignShift={dataDashboard}
            locationData={dataLocation}
            locationState={dataSelectLocation}
            onChangeLocation={(e) => {
              setDataSelectLocation(e.target.value);
            }}
            // roleUser={currentRole === "Admin"}
            divHeightEmployee={remainingHeight}
          />
          {/* <TestShiftListBox
                        dataDate={dateValue}
                        dataEmployees={dataEmployeesShift}
                        shiftDataTemplate={dataShift}
                        dataAssignShift={dataDashboard}
                        locationData={dataLocation}
                        locationState={dataSelectLocation}
                        onChangeLocation={(e) => {
                            setDataSelectLocation(e.target.value);
                        }}
                    /> */}
        </div>

        <div className="flex flex-col w-full sm:!w-1/4">
          <CustomCalendar setDate={setDateData} />
          <div className="text-container flex justify-between items-center">
            <p className="text-label">What's on in {month}?</p>
            <button
              className="bg-white text-black w-10 h-10 flex-shrink-0 m-1 border-none text-2xl"
              onClick={HandleToggleTabsForm}
            >
              +
            </button>
          </div>
          <Tabs options={exampleOptions} width={95} day={day} />
          {showTabsForm && (
            <TestModalDialog
              title="Create New Events"
              onClose={HandleToggleTabsForm}
              className={"w-[700px]"}
            >
              <EventForm onClose={HandleToggleTabsForm} />
            </TestModalDialog>
          )}
        </div>
      </div>
    </>
  );
};

export default AdminDashboardPage;
